<template>
    <v-container
        id="vizitka-instrum"
        tag="section"
    >
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Страница-визитка
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Рефссылка, настройки
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                      <p class="mb-7">
                        Визитка - Личный мини-сайт с информацией о тебе как о партнере компании LR.
                      </p>
                      <p class="mb-5">
                        Цель - Заинтересовать тобой и твоей деятельностью собеседника и возможность использования в соцсетях. Удобное расположение всех твоих контактов, мессенджеров и соцсетей в одном месте. Красивая, уникальная бизнес-визитка с возможностями индивидуальной настройки и добавления ссылок, фотографий и отзывов.
                      </p>
                      <p class="mb-10">
                        <a href="https://teletype.in/@starsales_1/r4zLXhBMnKP" target="_blank">Инструкция по использованию</a>
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                            Твоя реферальная ссылка
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <router-link
                                            color="success"
                                            :to="{name: 'Vizitka', query: { partner: $store.state.user.profile.lr_number }}"
                                        >
                                            Страница-визитка
                                        </router-link>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Твоя реферальная ссылка на страницу-визитку</strong>:
                                        <v-text-field
                                            :value="`https://newlvl.net/vizitka/?partner=${$store.state.user.profile.lr_number}`"
                                            readonly
                                            id="referLinkVizitka"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyRefLinkVizitka"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <span>
                                        <v-icon class="mr-2" color="blue">mdi-numeric-2-box-multiple</v-icon>
                                        Настройка
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        Страница-визитка имеет 6 блоков:
                                    </p>
                                    <ul class="ul-price">
                                        <li>
                                            <v-icon class="mr-2" color="red">mdi-numeric-1-box-multiple</v-icon>
                                            Ваши аватар, имя и фамилия. Указываются в Профиле.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="blue">mdi-numeric-2-box-multiple</v-icon>
                                            Блок вашей мини истории.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-numeric-3-box-multiple</v-icon>
                                            Блок, в котором вы можете разместить фото вашего чека от ЛР.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="orange">mdi-numeric-4-box-multiple</v-icon>
                                            Блок, в котором вы можете разместить фото вашего авто от ЛР.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="pink">mdi-numeric-5-box-multiple</v-icon>
                                            Блок дополнительных изображений.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="pink">mdi-numeric-6-box-multiple</v-icon>
                                            Блок дополнительной информации и призыва к действию.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="purple">mdi-numeric-7-box-multiple</v-icon>
                                            Блок с контактами, по которым кандидат может с вами связаться. Указываются в Профиле.
                                        </li>
                                    </ul>
                                    <p class="mt-5">
                                        Посмотрите видео по настройке страницы-визитки:
                                    </p>
                                    <div class="thumb-wrap">
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/KmdP-vv2Eh0"
                                            title="Настройки страницы-визитки"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        >
                                        </iframe>
                                    </div>
                                    <v-expansion-panels
                                        popout
                                        focusable
                                        class="exp-panel-title mt-10"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-alert-circle</v-icon>
                                            Если видео не грузится
                                        </span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <p class="mt-10">
                                                    Если на вашем устройстве не загружается видео с youtube, то вы можете скачать и посмотреть
                                                    видео-инструкцию с гугл-диска:
                                                    <a href="https://drive.google.com/drive/folders/1xNr_szC3_LhYkknkP6V5jSRGk2Vk7Kdu?usp=sharing">Видео-инструкции</a>
                                                </p>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>

                                    <v-divider class="mt-7"></v-divider>
                                    <p class="mt-5">
                                        <v-icon class="mr-2" color="blue">mdi-numeric-2-box-multiple</v-icon>
                                        В блоке мини истории мы рекомендуем написать немного о себе и своих достижениях в ЛР.
                                    </p>
                                    <p>
                                        <strong>Как написать мини историю</strong>
                                    </p>
                                    <p>
                                        Примерный план:
                                    </p>
                                    <ul class="ul-price">
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Чем занимались до ЛР. Если предприниматель, то чем именно.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Что не устраивало.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Доход через пол года или через год или когда вышли на максимальный чек.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Через какое время получили первый авто.
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Также можно указать какую-то мечту, которую удалось осуществить благодаря LR: купили квартиру, побывали в какой-то стране, поправили здоровье и т.п.
                                        </li>
                                    </ul>
                                    <p class="mt-5">
                                        Очень краткий пример:
                                        <br>
                                        <span style="font-style: italic">
                                            "Алексей, предприниматель, был свой магазин одежды. В ЛР пришел, потому что устал быть в бизнесе 24 на 7.
                                            Через пол года доход от ЛР составил 40 000 руб. Через год получил авто от компании."
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Новичкам</strong>, у которых пока нет чека и автомобиля от ЛР, мы рекомендуем написать:
                                        почему вы пришли в ЛР, чем занимаетесь или занимались до прихода в ЛР, какие цели планируете реализовать с помощью ЛР.
                                    </p>
                                    <p>
                                        Рекомендуем со временем корректировать свою мини-историю: добавлять новые достижения в ЛР и какие новые цели вы смогли
                                        реализовать благодаря ЛР.
                                    </p>
                                    <v-divider></v-divider>
                                    <p class="mt-5">
                                      <a href="https://teletype.in/@newlvl21/nNXL4QJ53Wl" target="_blanc">Инструкция по изменению цвета текста и заливки</a>
                                    </p>
                                    <p class="mt-5">
                                        Добавить мини историю:
                                    </p>
                                    <vue-editor
                                        v-model="profileData.about_me_viz"
                                        :editor-toolbar="customToolbar1"
                                    />
                                    <div class="invalid-feedback" v-if="errors.about_me_viz">{{ errors.about_me_viz[0] }}</div>

                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <v-divider class="mt-7"></v-divider>

                                    <p class="mt-7">
                                        <v-icon class="mr-2" color="green">mdi-numeric-3-box-multiple</v-icon>
                                        Если у вас есть чек от LR вы можете показать его вашим кандидатам.
                                    </p>
                                    <p>
                                        Для этого необходимо в
                                        <router-link color="success" :to="{name: 'Profile'}">Профиле</router-link>
                                        добавить фото чека.
                                    </p>
                                    <v-divider class="mt-7"/>
                                    <p class="mt-7">
                                        <v-icon class="mr-2" color="orange">mdi-numeric-4-box-multiple</v-icon>
                                        Если у вас есть авто от LR вы можете показать его вашим кандидатам.
                                    </p>
                                    <p>
                                        Для этого необходимо в
                                        <router-link color="success" :to="{name: 'Profile'}">Профиле</router-link>
                                        добавить фото авто.
                                    </p>

                                    <v-divider class="mt-7"></v-divider>

                                    <p class="mt-7">
                                        <v-icon class="mr-2" color="purple">mdi-numeric-5-box-multiple</v-icon>
                                        Блок дополнительных изображений
                                    </p>
                                    <p class="mt-5">
                                        Укажите заголовок для 1-го изображения:
                                    </p>
                                    <v-text-field
                                        label="Заголовок для 1-го изображения"
                                        type="text"
                                        v-model.trim="profileData.viz_title_one"
                                        :error-messages="vizTitleOneErrors"
                                        @input="$v.profileData.viz_title_one.$touch()"
                                        @blur="$v.profileData.viz_title_one.$touch()"
                                    />
                                    <div class="invalid-feedback" v-if="errors.viz_title_one">{{ errors.viz_title_one[0] }}</div>
                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-0 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <div class="d-flex grow flex-wrap">
                                        <div>
                                            <img v-if="profileVizImgOne && !profileNewVizImgOne" :src="`${ $store.state.serverPath }/storage/${ profileVizImgOne }`"
                                                alt=""
                                                style="height: 100px;"
                                            >
                                            <img
                                                v-show="profileNewVizImgOne"
                                                alt=""
                                                style="height: 100px;"
                                                src=""
                                                ref="newVizImgOne"
                                            />
                                        </div>
                                        <v-file-input
                                            accept="image/*"
                                            label="Выбрать изображение"
                                            @change="attachVizImgOne"
                                            ref="vizImgOne"
                                        ></v-file-input>
                                        <v-card-text class="text-center">
                                            <div class="input-group mb-3">
                                                <v-btn
                                                    v-if="profileData.viz_img_one"
                                                    color="grey"
                                                    rounded
                                                    class="mr-2"
                                                    @click="deleteVizImgOne"
                                                >
                                                    Удалить
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    rounded
                                                    class="mr-0"
                                                    @click="saveVizImgOne"
                                                >
                                                    Сохранить 1-е изображение
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <v-progress-linear
                                        :active="loadingVizImgOne"
                                        :indeterminate="loadingVizImgOne"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear> 
                                    
                                    <v-divider class="mt-1"></v-divider>

                                    <p class="mt-5">
                                        Укажите заголовок для 2-го изображения:
                                    </p>
                                    <v-text-field
                                        label="Заголовок для 2-го изображения"
                                        type="text"
                                        v-model.trim="profileData.viz_title_two"
                                        :error-messages="vizTitleTwoErrors"
                                        @input="$v.profileData.viz_title_two.$touch()"
                                        @blur="$v.profileData.viz_title_two.$touch()"
                                    />
                                    <div class="invalid-feedback" v-if="errors.viz_title_two">{{ errors.viz_title_two[0] }}</div>
                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-0 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <div class="d-flex grow flex-wrap">
                                        <div>
                                            <img v-if="profileVizImgTwo && !profileNewVizImgTwo" :src="`${ $store.state.serverPath }/storage/${ profileVizImgTwo }`"
                                                alt=""
                                                style="height: 100px;"
                                            >
                                            <img
                                                v-show="profileNewVizImgTwo"
                                                alt=""
                                                style="height: 100px;"
                                                src=""
                                                ref="newVizImgTwo"
                                            />
                                        </div>
                                        <v-file-input
                                            accept="image/*"
                                            label="Выбрать изображение"
                                            @change="attachVizImgTwo"
                                            ref="vizImgTwo"
                                        ></v-file-input>
                                        <v-card-text class="text-center">
                                            <div class="input-group mb-3">
                                                <v-btn
                                                    v-if="profileData.viz_img_two"
                                                    color="grey"
                                                    rounded
                                                    class="mr-2"
                                                    @click="deleteVizImgTwo"
                                                >
                                                    Удалить
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    rounded
                                                    class="mr-0"
                                                    @click="saveVizImgTwo"
                                                >
                                                    Сохранить 2-е изображение
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <v-progress-linear
                                        :active="loadingVizImgTwo"
                                        :indeterminate="loadingVizImgTwo"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>
                                    
                                    <v-divider class="mt-1"></v-divider>

                                    <p class="mt-5">
                                        Укажите заголовок для 3-го изображения:
                                    </p>
                                    <v-text-field
                                        label="Заголовок для 3-го изображения"
                                        type="text"
                                        v-model.trim="profileData.viz_title_three"
                                        :error-messages="vizTitleThreeErrors"
                                        @input="$v.profileData.viz_title_three.$touch()"
                                        @blur="$v.profileData.viz_title_three.$touch()"
                                    />
                                    <div class="invalid-feedback" v-if="errors.viz_title_three">{{ errors.viz_title_three[0] }}</div>
                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-0 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <div class="d-flex grow flex-wrap">
                                        <div>
                                            <img v-if="profileVizImgThree && !profileNewVizImgThree" :src="`${ $store.state.serverPath }/storage/${ profileVizImgThree }`"
                                                alt=""
                                                style="height: 100px;"
                                            >
                                            <img
                                                v-show="profileNewVizImgThree"
                                                alt=""
                                                style="height: 100px;"
                                                src=""
                                                ref="newVizImgThree"
                                            />
                                        </div>
                                        <v-file-input
                                            accept="image/*"
                                            label="Выбрать изображение"
                                            @change="attachVizImgThree"
                                            ref="vizImgThree"
                                        ></v-file-input>
                                        <v-card-text class="text-center">
                                            <div class="input-group mb-3">
                                                <v-btn
                                                    v-if="profileData.viz_img_three"
                                                    color="grey"
                                                    rounded
                                                    class="mr-2"
                                                    @click="deleteVizImgThree"
                                                >
                                                    Удалить
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    rounded
                                                    class="mr-0"
                                                    @click="saveVizImgThree"
                                                >
                                                    Сохранить 3-е изображение
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <v-progress-linear
                                        :active="loadingVizImgThree"
                                        :indeterminate="loadingVizImgThree"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear> 

                                    <v-divider class="mt-1"></v-divider>

                                    <p class="mt-5">
                                        Укажите заголовок для 4-го изображения:
                                    </p>
                                    <v-text-field
                                        label="Заголовок для 4-го изображения"
                                        type="text"
                                        v-model.trim="profileData.viz_title_four"
                                        :error-messages="vizTitleFourErrors"
                                        @input="$v.profileData.viz_title_four.$touch()"
                                        @blur="$v.profileData.viz_title_four.$touch()"
                                    />
                                    <div class="invalid-feedback" v-if="errors.viz_title_four">{{ errors.viz_title_four[0] }}</div>
                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-0 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <div class="d-flex grow flex-wrap">
                                        <div>
                                            <img v-if="profileVizImgFour && !profileNewVizImgFour" :src="`${ $store.state.serverPath }/storage/${ profileVizImgFour }`"
                                                alt=""
                                                style="height: 100px;"
                                            >
                                            <img
                                                v-show="profileNewVizImgFour"
                                                alt=""
                                                style="height: 100px;"
                                                src=""
                                                ref="newVizImgFour"
                                            />
                                        </div>
                                        <v-file-input
                                            accept="image/*"
                                            label="Выбрать изображение"
                                            @change="attachVizImgFour"
                                            ref="vizImgFour"
                                        ></v-file-input>
                                        <v-card-text class="text-center">
                                            <div class="input-group mb-3">
                                                <v-btn
                                                    v-if="profileData.viz_img_four"
                                                    color="grey"
                                                    rounded
                                                    class="mr-2"
                                                    @click="deleteVizImgFour"
                                                >
                                                    Удалить
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    rounded
                                                    class="mr-0"
                                                    @click="saveVizImgFour"
                                                >
                                                    Сохранить 4-е изображение
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <v-progress-linear
                                        :active="loadingVizImgFour"
                                        :indeterminate="loadingVizImgFour"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear> 

                                    <v-divider class="mt-1"></v-divider>

                                    <p class="mt-5">
                                        Укажите заголовок для 5-го изображения:
                                    </p>
                                    <v-text-field
                                        label="Заголовок для 5-го изображения"
                                        type="text"
                                        v-model.trim="profileData.viz_title_five"
                                        :error-messages="vizTitleFiveErrors"
                                        @input="$v.profileData.viz_title_five.$touch()"
                                        @blur="$v.profileData.viz_title_five.$touch()"
                                    />
                                    <div class="invalid-feedback" v-if="errors.viz_title_five">{{ errors.viz_title_five[0] }}</div>
                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-0 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <div class="d-flex grow flex-wrap">
                                        <div>
                                            <img v-if="profileVizImgFive && !profileNewVizImgFive" :src="`${ $store.state.serverPath }/storage/${ profileVizImgFive }`"
                                                alt=""
                                                style="height: 100px;"
                                            >
                                            <img
                                                v-show="profileNewVizImgFive"
                                                alt=""
                                                style="height: 100px;"
                                                src=""
                                                ref="newVizImgFive"
                                            />
                                        </div>
                                        <v-file-input
                                            accept="image/*"
                                            label="Выбрать изображение"
                                            @change="attachVizImgFive"
                                            ref="vizImgFive"
                                        ></v-file-input>
                                        <v-card-text class="text-center">
                                            <div class="input-group mb-3">
                                                <v-btn
                                                    v-if="profileData.viz_img_five"
                                                    color="grey"
                                                    rounded
                                                    class="mr-2"
                                                    @click="deleteVizImgFive"
                                                >
                                                    Удалить
                                                </v-btn>
                                                <v-btn
                                                    color="success"
                                                    rounded
                                                    class="mr-0"
                                                    @click="saveVizImgFive"
                                                >
                                                    Сохранить 5-е изображение
                                                </v-btn>
                                            </div>
                                        </v-card-text>
                                    </div>
                                    <v-progress-linear
                                        :active="loadingVizImgFive"
                                        :indeterminate="loadingVizImgFive"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear> 

                                    <v-divider class="mt-7"></v-divider>

                                    <p class="mt-7">
                                        <v-icon class="mr-2" color="pink">mdi-numeric-6-box-multiple</v-icon>
                                        Блок дополнительной информации и призыва к действию
                                    </p>
                                    <p>
                                        В этом блоке мы рекомендуем указать, например:
                                    </p>
                                    <ul class="ul-price">
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Какую-либо дополнительную информацию о том, что вы предлагает вашему кандидату
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Ссылку на ваш клиентский чат и описание к нему (см. видео по настройке страницы-визитки)
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Ссылку на какой-либо бонус или подарок для вашего кандидата
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Ссылку на прохождение онлайн-теста и описание к ней
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Ссылку на бизнес-презентацию и описание к ней
                                        </li>
                                        <li>
                                            <v-icon class="mr-2" color="green">mdi-check-all</v-icon>
                                            Призыв к действию
                                        </li>
                                    </ul>
                                    <v-divider class="mt-7"></v-divider>
                                    <p class="mt-5">
                                      <a href="https://teletype.in/@newlvl21/nNXL4QJ53Wl" target="_blanc">Инструкция по изменению цвета текста и заливки</a>
                                    </p>
                                    <p class="mt-5">Дополнительная информация и призыв к действию:</p>
                                    <vue-editor
                                        v-model="profileData.dop_viz"
                                        :editor-toolbar="customToolbar2"
                                    />
                                    <div class="invalid-feedback" v-if="errors.dop_viz">{{ errors.dop_viz[0] }}</div>

                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>

                                    <v-divider class="mt-7"></v-divider>
                                    <p class="mt-7 mb-7">
                                        <v-icon class="mr-2" color="red">mdi-palette-swatch</v-icon>
                                        Выбрать дизайн страницы-визитки
                                    </p>
                                    <v-select
                                        prepend-icon="mdi-palette"
                                        :items="vizDesignItems"
                                        label="Дизайн для страницы-визитки"
                                        v-model.trim="profileData.viz_design"
                                        :error-messages="vizDesignErrors"
                                        @change="$v.profileData.viz_design.$touch()"
                                        @blur="$v.profileData.viz_design.$touch()"
                                    ></v-select>
                                    <div class="invalid-feedback" v-if="errors.viz_design">{{ errors.viz_design[0] }}</div>

                                    <div
                                        v-if="profileData.viz_design === 'default'"
                                        class="viz-disign-div"
                                    >
                                        <p>Стандартная тема</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/default_mini_viz.png"
                                                @click.stop="dialogDefault = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'dark'"
                                        class="viz-disign-div"
                                    >
                                        <p>Темная тема</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/dark_mini_viz.png"
                                                @click.stop="dialogDark = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'brown'"
                                        class="viz-disign-div"
                                    >
                                        <p>Коричневая тема</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/brown_mini_viz.png"
                                                @click.stop="dialogBrown = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'white'"
                                        class="viz-disign-div"
                                    >
                                        <p>Светлая тема</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/white_viz_mini.png"
                                                @click.stop="dialogWhite = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'white2'"
                                        class="viz-disign-div"
                                    >
                                        <p>Светлая тема 2</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/white2_viz_mini.png"
                                                @click.stop="dialogWhite2 = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'dark2'"
                                        class="viz-disign-div"
                                    >
                                        <p>Темная тема 2</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/dark2_mini_viz.png"
                                                @click.stop="dialogDark2 = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>
                                    <div
                                        v-if="profileData.viz_design === 'pink'"
                                        class="viz-disign-div"
                                    >
                                        <p>Розовая тема</p>
                                        <p style="width: 100%; max-width: 450px; height: auto">
                                            <v-img
                                                class="align-center"
                                                max-width="200px"
                                                src="/img/vizitka/pink_viz_mini.png"
                                                @click.stop="dialogPink = true"
                                            >
                                                <v-row justify="center">
                                                    <v-icon color="grey" large>mdi-magnify-plus-outline</v-icon>
                                                </v-row>
                                            </v-img>
                                        </p>
                                    </div>

                                    <v-btn
                                        color="success"
                                        class="mr-0 mt-5 mb-5"
                                        @click="editProfileData"
                                    >
                                        Сохранить данные
                                    </v-btn>
                                    <v-progress-linear
                                        :active="loading"
                                        :indeterminate="loading"
                                        absolute
                                        bottom
                                        color="success accent-4"
                                    ></v-progress-linear>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-dialog
                            v-model="dialogDefault"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/default_viz.png"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogDark"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/dark_viz.png"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogBrown"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/brown_viz.png"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogDark2"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/dark2_viz.png"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogWhite"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/white_viz.jpg"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogWhite2"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/white2_viz.jpg"
                            ></v-img>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogPink"
                            max-width="300"
                        >
                            <v-img
                                src="/img/vizitka/pink_viz.jpg"
                            ></v-img>
                        </v-dialog>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import * as userService from '../../../services/user_service'
import { mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

export default {
    name: "VizitkaInstrum",
    mixins: [validationMixin],
    data: () => ({
        customToolbar1: [
            ["bold", "italic", "underline"],
            [{ align: "" }, { align: "center" }, { align: "right" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["link"]
        ],
        customToolbar2: [
            ["bold", "italic", "underline"],
            ["link"]
        ],
        vizDesignItems: [
            'default',
            'dark2',
            'white',
            'white2',
            'pink',
            'dark',
            'brown'
        ],
        errors: {},
        loading: false,
        loadingVizImgOne: false,
        loadingVizImgTwo: false,
        loadingVizImgThree: false,
        loadingVizImgFour: false,
        loadingVizImgFive: false,
        profileNewVizImgOne: '',
        profileNewVizImgTwo: '',
        profileNewVizImgThree: '',
        profileNewVizImgFour: '',
        profileNewVizImgFive: '',
        dialogDefault: false,
        dialogDark: false,
        dialogBrown: false,
        dialogDark2: false,
        dialogPink: false,
        dialogWhite: false,
        dialogWhite2: false,
    }),
    computed: {
        ...mapGetters({
            profile: 'user/profile',
        }),
        profileData() {
            return this.profile
        },
        profileVizImgOne() {
            return this.profile.viz_img_one
        },
        profileVizImgTwo() {
            return this.profile.viz_img_two
        },
        profileVizImgThree() {
            return this.profile.viz_img_three
        },
        profileVizImgFour() {
            return this.profile.viz_img_four
        },
        profileVizImgFive() {
            return this.profile.viz_img_five
        },
        vizTitleOneErrors () {
            const errors = []
            if (!this.$v.profileData.viz_title_one.$dirty) return errors
            !this.$v.profileData.viz_title_one.minLength && errors.push('Минимум 2 буквы')
            !this.$v.profileData.viz_title_one.maxLength && errors.push('Максимум 30 букв')
            return errors
        },
        vizTitleTwoErrors () {
            const errors = []
            if (!this.$v.profileData.viz_title_two.$dirty) return errors
            !this.$v.profileData.viz_title_two.minLength && errors.push('Минимум 2 буквы')
            !this.$v.profileData.viz_title_two.maxLength && errors.push('Максимум 30 букв')
            return errors
        },
        vizTitleThreeErrors () {
            const errors = []
            if (!this.$v.profileData.viz_title_three.$dirty) return errors
            !this.$v.profileData.viz_title_three.minLength && errors.push('Минимум 2 буквы')
            !this.$v.profileData.viz_title_three.maxLength && errors.push('Максимум 30 букв')
            return errors
        },
        vizTitleFourErrors () {
            const errors = []
            if (!this.$v.profileData.viz_title_four.$dirty) return errors
            !this.$v.profileData.viz_title_four.minLength && errors.push('Минимум 2 буквы')
            !this.$v.profileData.viz_title_four.maxLength && errors.push('Максимум 30 букв')
            return errors
        },
        vizTitleFiveErrors () {
            const errors = []
            if (!this.$v.profileData.viz_title_five.$dirty) return errors
            !this.$v.profileData.viz_title_five.minLength && errors.push('Минимум 2 буквы')
            !this.$v.profileData.viz_title_five.maxLength && errors.push('Максимум 30 букв')
            return errors
        },
        aboutMeVizErrors() {
            const errors = []
            if (!this.$v.profileData.about_me_viz.$dirty) return errors
            !this.$v.profileData.about_me_viz.minLength && errors.push('Минимум 10 символов')
            !this.$v.profileData.about_me_viz.maxLength && errors.push('Максимум 3000 символов')
            return errors
        },
        dopVizErrors() {
            const errors = []
            if (!this.$v.profileData.dop_viz.$dirty) return errors
            !this.$v.profileData.dop_viz.minLength && errors.push('Минимум 10 символов')
            !this.$v.profileData.dop_viz.maxLength && errors.push('Максимум 3000 символов')
            return errors
        },
        vizDesignErrors() {
            const errors = []
            if (!this.$v.profileData.viz_design.$dirty) return errors
            !this.$v.profileData.viz_design.required && errors.push('Обязательно для заполнения')
            return errors
        },
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            updateProfile: 'user/updateProfile',
        }),
        copyRefLinkVizitka () {
            let referLinkVizitka = document.getElementById('referLinkVizitka')

            referLinkVizitka.select()

            document.execCommand('copy')
        },
        attachVizImgOne() {
            if(this.$refs.vizImgOne.$refs.input.files[0]) {
                this.profileNewVizImgOne = this.$refs.vizImgOne.$refs.input.files[0]
                let reader = new FileReader()

                reader.addEventListener('load', function () {
                    this.$refs.newVizImgOne.src = reader.result
                }.bind(this), false)

                reader.readAsDataURL(this.profileNewVizImgOne)
            } else {
                this.$refs.newVizImgOne.src = ''
            }
        },
        saveVizImgOne() {
            if (this.profileNewVizImgOne) {
                this.loadingVizImgOne = true

                let formData = new FormData()
                formData.append('viz_img_one', this.profileNewVizImgOne)

                userService.updateVizImgOne(this.profile.id, formData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loadingVizImgOne = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.profileNewVizImgOne = ''
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 500:
                                this.loadingVizImgOne = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loadingVizImgOne = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Вы не выбрали изображение',
                })
            }
        },
        attachVizImgTwo() {
            if(this.$refs.vizImgTwo.$refs.input.files[0]) {
                this.profileNewVizImgTwo = this.$refs.vizImgTwo.$refs.input.files[0]
                let reader = new FileReader()

                reader.addEventListener('load', function () {
                    this.$refs.newVizImgTwo.src = reader.result
                }.bind(this), false)

                reader.readAsDataURL(this.profileNewVizImgTwo)
            } else {
                this.$refs.newVizImgTwo.src = ''
            }
        },
        saveVizImgTwo() {
            if (this.profileNewVizImgTwo) {
                this.loadingVizImgTwo = true

                let formData = new FormData()
                formData.append('viz_img_two', this.profileNewVizImgTwo)

                userService.updateVizImgTwo(this.profile.id, formData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loadingVizImgTwo = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.profileNewVizImgTwo = ''
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 500:
                                this.loadingVizImgTwo = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loadingVizImgTwo = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Вы не выбрали изображение',
                })
            }
        },
        attachVizImgThree() {
            if(this.$refs.vizImgThree.$refs.input.files[0]) {
                this.profileNewVizImgThree = this.$refs.vizImgThree.$refs.input.files[0]
                let reader = new FileReader()

                reader.addEventListener('load', function () {
                    this.$refs.newVizImgThree.src = reader.result
                }.bind(this), false)

                reader.readAsDataURL(this.profileNewVizImgThree)
            } else {
                this.$refs.newVizImgThree.src = ''
            }
        },
        saveVizImgThree() {
            if (this.profileNewVizImgThree) {
                this.loadingVizImgThree = true

                let formData = new FormData()
                formData.append('viz_img_three', this.profileNewVizImgThree)

                userService.updateVizImgThree(this.profile.id, formData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loadingVizImgThree = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.profileNewVizImgThree = ''
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 500:
                                this.loadingVizImgThree = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loadingVizImgThree = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Вы не выбрали изображение',
                })
            }
        },
        attachVizImgFour() {
            if(this.$refs.vizImgFour.$refs.input.files[0]) {
                this.profileNewVizImgFour = this.$refs.vizImgFour.$refs.input.files[0]
                let reader = new FileReader()

                reader.addEventListener('load', function () {
                    this.$refs.newVizImgFour.src = reader.result
                }.bind(this), false)

                reader.readAsDataURL(this.profileNewVizImgFour)
            } else {
                this.$refs.newVizImgFour.src = ''
            }
        },
        saveVizImgFour() {
            if (this.profileNewVizImgFour) {
                this.loadingVizImgFour = true

                let formData = new FormData()
                formData.append('viz_img_four', this.profileNewVizImgFour)

                userService.updateVizImgFour(this.profile.id, formData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loadingVizImgFour = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.profileNewVizImgFour = ''
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 500:
                                this.loadingVizImgFour = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loadingVizImgFour = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Вы не выбрали изображение',
                })
            }
        },
        attachVizImgFive() {
            if(this.$refs.vizImgFive.$refs.input.files[0]) {
                this.profileNewVizImgFive = this.$refs.vizImgFive.$refs.input.files[0]
                let reader = new FileReader()

                reader.addEventListener('load', function () {
                    this.$refs.newVizImgFive.src = reader.result
                }.bind(this), false)

                reader.readAsDataURL(this.profileNewVizImgFive)
            } else {
                this.$refs.newVizImgFive.src = ''
            }
        },
        saveVizImgFive() {
            if (this.profileNewVizImgFive) {
                this.loadingVizImgFive = true

                let formData = new FormData()
                formData.append('viz_img_five', this.profileNewVizImgFive)

                userService.updateVizImgFive(this.profile.id, formData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loadingVizImgFive = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.profileNewVizImgFive = ''
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 500:
                                this.loadingVizImgFive = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loadingVizImgFive = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Вы не выбрали изображение',
                })
            }
        },
        deleteVizImgOne() {
            this.loadingVizImgOne = true
            userService.deleteVizImgOne(this.profile.id)
                .then((response) => {
                    this.updateProfile(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loadingVizImgOne = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.loadingVizImgOne = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loadingVizImgOne = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        deleteVizImgTwo() {
            this.loadingVizImgTwo = true
            userService.deleteVizImgTwo(this.profile.id)
                .then((response) => {
                    this.updateProfile(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loadingVizImgTwo = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.loadingVizImgTwo = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loadingVizImgTwo = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        deleteVizImgThree() {
            this.loadingVizImgThree = true
            userService.deleteVizImgThree(this.profile.id)
                .then((response) => {
                    this.updateProfile(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loadingVizImgThree = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.loadingVizImgThree = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loadingVizImgThree = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        deleteVizImgFour() {
            this.loadingVizImgFour = true
            userService.deleteVizImgFour(this.profile.id)
                .then((response) => {
                    this.updateProfile(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loadingVizImgFour = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.loadingVizImgFour = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loadingVizImgFour = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        deleteVizImgFive() {
            this.loadingVizImgFive = true
            userService.deleteVizImgFive(this.profile.id)
                .then((response) => {
                    this.updateProfile(response.data)
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.loadingVizImgFive = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.loadingVizImgFive = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.loadingVizImgFive = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
        editProfileData() {
            this.$v.profileData.$touch()
            if (!this.$v.profileData.$invalid) {
                this.loading = true
                userService.updateProfile(this.profileData.id, this.profileData)
                    .then((response) => {
                        this.updateProfile(response.data)
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        }
    },
    validations: {
        profileData: {
            viz_title_one: {
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            viz_title_two: {
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            viz_title_three: {
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            viz_title_four: {
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            viz_title_five: {
                minLength: minLength(2),
                maxLength: maxLength(30),
            },
            about_me_viz: {
                minLength: minLength(10),
                maxLength: maxLength(3000)
            },
            dop_viz: {
                minLength: minLength(10),
                maxLength: maxLength(3000)
            },
            viz_design: {
                required
            },
        },
    }
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.ul-price
    list-style-type: none

.ul-price li
    padding-bottom: 10px
    line-height: 26px !important

.viz-disign-div
    margin-top: 20px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0
</style>
